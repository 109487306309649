import React, { useState }  from 'react';
import axios from 'axios';
import './App.css';
import MyComponent from './barra'
import Mapa1 from './mapa1'
import Mapa2 from './mapa2'
import Mapa3 from './mapa3'
import Mapa4 from './mapa4'
import Mapa5 from './mapa5'
import MyComponent1 from './mapeo'
import { Amplify } from 'aws-amplify';
import '@aws-amplify/ui-react/styles.css';
import awsExports from './aws-exports';
Amplify.configure(awsExports);


function Scan({user}) {
  const username = user.username;
  const [nombre, setNombre] = useState('');
  const backend = process.env.REACT_APP_POST_BACKEND_PRO;
  function parar(){
    const data = '';
    axios.post(backend, data)
    .then(response => this?.setState({ articleId: response?.data }))
    .catch(error => {
      this?.setState({ errorMessage: error?.message });
      console.error('There was an error!', error);
    });
    axios.get(backend)
    .then(response => this.setState({response }));
  }
  function reload(){
    window.location.reload();
  }
  const handleSubmit = (event) => {
    
    event.preventDefault();
    let data = {
      nombre: nombre,
      usuario: username,
    };
    alert('Bien hecho!!')
    axios.post(backend, data)
    .then(response => this?.setState({ articleId: response?.data }))
    .catch(error => {
      this?.setState({ errorMessage: error?.message });
      console.error('There was an error!', error);
    });
    axios.get(backend)
    .then(response => this.setState({response }));
  };

    return (
      <div>
        <MyComponent/>
   <div id="title">
    <div class="row row-sm-w-100 py-3 text-center text-primary font-weight-bold">
      <h1>Enlace azul - Demo</h1>
    </div>
  </div>
 <div id="bloqueUno" class="w-75 mx-auto"> 
  <div class="row row-lg-w-75 row-sm-w-100 row-sm-d-block justify-content-between">
    <div class="col-sm-w-100 col-md-6">
      <div id="seleccioneElDispositivo" class="mt-3">
        <div class="row ">
          <h3 class="text-center text-primary h3 ">Seleccione el dispositivo</h3>
        </div>
      </div>
    <div id="select" class=" my-1">
      <div class="row container-fluid justify-content-between"> 
        <div class="col-9">
        <form onSubmit={handleSubmit}>
        <select class="form-select" value={nombre} onChange={(e) => setNombre(e.target.value)}>
          <option selected class="negrita" >Dispositivos</option>
          <option id="b2" value="BC57290406DC">Beacon A</option>
          <option id="b4" value="BC5729031435">Beacon B</option>
          <option id="b1" value="BC572903142E">Beacon C</option>
          <option id="b3" value="BC57290406D1">Beacon D</option>
          <option id="b5" value="BC572907E112">S2</option>
          <option id="b6" value="BC57290E2808">K7</option>
          <option id="b8" value="BC572907EB53">S3</option>
          <option id="b9" value="BC57290D7830">S4</option>
          
          {/* <option id="b4" value="BC572903F536">Beacon W3 F536</option>
          <option id="b2" value="BC5729031435">Beacon K5P 1435</option>
          <option id="b6" value="BC5729059544">Beacon K1 9544</option>
          <option id="b6" value="BC57290576CC">Beacon S5 76CC</option> */}
        </select>
        </form>
      </div>
      <div class="col-3 ">
      <button class="btn btn-outline-primary " data-name="baliza1" id="ver1" onClick={handleSubmit}>Escanear</button>
    </div>
</div>
<div id="botones" class=" botones-md-w-100 ">
  <div class="row  my-3 row-md-w-100">

    <div class="col-3  col-sm-4 col-md-3 col-lg-4 text-center "> 
        <button data-name="baliza" id="ver" onClick={reload} class="btn btn-outline-primary ">Cargar</button>
      </div>
      <div class="col-3 col-sm-4 col-md-3 col-lg-4 text-center"> 
          <select name="baliza" id="baliza" style={{ display: "none" }}>
            <option value="1"></option>
          </select>
        <button onClick={parar} id="stop"class="btn btn-outline-primary ">Pausar</button>

    </div>
      <div class="col-6 col-sm-4 col-md-6 col-lg-4 text-center "> 
        <button id="scan" class="btn btn-outline-primary "><a href='/historico'>Tendencias</a></button>
      </div>
  </div>
</div>
</div>
<div id="title">
    <div class="row row-sm-w-100 py-3 text-center text-primary font-weight-bold">
        <Mapa1 username={username}/>
        <Mapa2 username={username}/>
        <Mapa3 username={username}/>
        <Mapa4 username={username}/>
        <Mapa5 username={username}/>
        </div>
        </div>
    </div>
    <div class="col-6">
    <h3 class="text-center text-primary h3 ">Ubicación</h3>
    <br></br>
    <MyComponent1 username={username}/>
    </div>
    </div>
    </div>
    </div>
    );
  }
  export default Scan;