import React, { useRef, useEffect } from 'react';
import './App.css';
import io from "socket.io-client";

function Mapa6(username) {
  let vista3 = useRef(null);
  useEffect(() => {
    const socket_url = process.env.REACT_APP_CONECT_SOCKET_PRO;
    const socket = io(socket_url, {
      transports: ["websocket"]
    });
    socket.on('connect', () => {
      socket.emit('enlace_azul', username.username);
    });
    socket.on('datos', (data) => {
      const uc1 = document.getElementById("uc1");
      const uc2 = document.getElementById("uc2");
      const uc3 = document.getElementById("uc3");
      const uc4 = document.getElementById("uc4");
      const uc5 = document.getElementById("uc5");
      const uc6 = document.getElementById("uc6");
      const uc7 = document.getElementById("uc7");

      if (uc2 && uc3 && uc4 && uc5 && uc6 && uc7) {
        document.getElementById("uc2").innerHTML = data.distancia5?.temp;
        let d5 = Math.round(Math.pow(10, ((-59 - (data.distancia5?.rssi)) / (10 * 2.7))) * 100) / 100;;
        document.getElementById("uc3").innerHTML = d5
        document.getElementById("uc4").innerHTML = data.distancia5?.time;
        if (data.distancia5?.humidty === undefined) {
          document.getElementById("uc1").innerHTML = '-';
        } else {
          document.getElementById("uc1").innerHTML = data.distancia5?.humidty;
        }
        if (data.distancia5?.pir === undefined) {
          document.getElementById("uc5").innerHTML = '-';
        } else {
          document.getElementById("uc5").innerHTML = data.distancia5?.pir;
        }
        if (data.distancia5?.lux === undefined) {
          document.getElementById("uc6").innerHTML = '-';
        } else {
          document.getElementById("uc6").innerHTML = data.distancia5?.lux;
        }
        if (data.distancia5?.cutoff === undefined) {
          document.getElementById("uc7").innerHTML = '-';
        } else {
          document.getElementById("uc7").innerHTML = data.distancia5?.cutoff;
        }
        if (isNaN(d5) && vista3.current) {
          vista3.current.style.display = 'none';
        } else if (vista3.current) {
          vista3.current.style.display = '';
        }
      }
    });
  }, []);
  return (
    <div>
      <table ref={vista3} border="1">
        <thead>
          <tr>
            <th scope="col">Ubicación</th>
            <th scope="col">Temp (grados)</th>
            <th scope="col">Humedad (%)</th>
            <th scope="col">Pir (Bool)</th>
            <th scope="col">Luz (Vat)</th>
            <th scope="col">cutoff (Vat)</th>
            <th scope="col">Fecha</th>
            <th scope="col">Distancia (m)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Urología: </td>
            <td id="uc2" data-sortable="true"></td>
            <td id="uc1" data-sortable="true"></td>
            <td id="uc5" data-sortable="true"></td>
            <td id="uc6" data-sortable="true"></td>
            <td id="uc7" data-sortable="true"></td>
            <td id="uc4" data-sortable="true"></td>
            <td id="uc3" data-sortable="true"></td>
          </tr>
        </tbody>
      </table>
    </div>

  );
}
export default Mapa6;