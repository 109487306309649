import React, { useRef, useEffect } from 'react';
import './App.css';
import io from "socket.io-client";

function Mapa2(username) {
  let vista = useRef(null);
  useEffect(() => {
    const socket_url = process.env.REACT_APP_CONECT_SOCKET_PRO;
    const socket = io(socket_url, {
      transports: ["websocket"]
    });
    socket.on('connect', () => {
      socket.emit('enlace_azul', username.username);
    });
    socket.on('datos', (data) => {
      const sc1 = document.getElementById("sc1");
      const sc2 = document.getElementById("sc2");
      const sc3 = document.getElementById("sc3");
      const sc4 = document.getElementById("sc4");
      const sc5 = document.getElementById("sc5");
      const sc6 = document.getElementById("sc6");
      const sc7 = document.getElementById("sc7");

      if (sc2 && sc3 && sc4 && sc5 && sc6 && sc7) {
        document.getElementById("sc2").innerHTML = data.distancia?.temp;
        let d = Math.round(Math.pow(10, ((-59 - (data.distancia?.rssi)) / (10 * 2.7))) * 100) / 100;;
        document.getElementById("sc3").innerHTML = d
        document.getElementById("sc4").innerHTML = data.distancia?.time;
        if (data.distancia?.humidty === undefined) {
          document.getElementById("sc1").innerHTML = '-';
        } else {
          document.getElementById("sc1").innerHTML = data.distancia?.humidty;
        }
        if (data.distancia?.pir === undefined) {
          document.getElementById("sc5").innerHTML = '-';
        } else {
          document.getElementById("sc5").innerHTML = data.distancia?.pir;
        }
        if (data.distancia?.lux === undefined) {
          document.getElementById("sc6").innerHTML = '-';
        } else {
          document.getElementById("sc6").innerHTML = data.distancia?.lux;
        }
        if (data.distancia?.cutoff === undefined) {
          document.getElementById("sc7").innerHTML = '-';
        } else {
          document.getElementById("sc7").innerHTML = data.distancia?.cutoff;
        }
        if (isNaN(d) && vista.current) {
          vista.current.style.display = 'none';
        } else if (vista.current) {
          vista.current.style.display = '';
        }
      }
    });
  }, []);
  return (
    <div>
      <table ref={vista} border="1">
        <thead>
          <tr>
            <th scope="col">Ubicación</th>
            <th scope="col">Temp (grados)</th>
            <th scope="col">Humedad (%)</th>
            <th scope="col">Pir (Bool)</th>
            <th scope="col">Luz (Vat)</th>
            <th scope="col">cutoff (Vat)</th>
            <th scope="col">Fecha</th>
            <th scope="col">Distancia (m)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Johnatan Mena Producción 1: </td>
            <td id="sc2"></td>
            <td id="sc1"></td>
            <td id="sc5"></td>
            <td id="sc6"></td>
            <td id="sc7"></td>
            <td id="sc4"></td>
            <td id="sc3"></td>
          </tr>
        </tbody>
      </table>
    </div>

  );
}
export default Mapa2;