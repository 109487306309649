import React, { useRef, useEffect } from 'react';
import './App.css';
import io from "socket.io-client";

function Mapa5(username) {
  let vista3 = useRef(null);
  useEffect(() => {
    const socket_url = process.env.REACT_APP_CONECT_SOCKET_PRO;
    const socket = io(socket_url, {
      transports: ["websocket"]
    });
    socket.on('connect', () => {
      socket.emit('enlace_azul', username.username);
    });
    socket.on('datos', (data) => {
      const ic1 = document.getElementById("ic1");
      const ic2 = document.getElementById("ic2");
      const ic3 = document.getElementById("ic3");
      const ic4 = document.getElementById("ic4");
      const ic5 = document.getElementById("ic5");
      const ic6 = document.getElementById("ic6");
      const ic7 = document.getElementById("ic7");

      if (ic2 && ic3 && ic4 && ic5 && ic6 && ic7) {
        document.getElementById("ic2").innerHTML = data.distancia4?.temp;
        let d4 = Math.round(Math.pow(10, ((-59 - (data.distancia4?.rssi)) / (10 * 2.7))) * 100) / 100;;
        document.getElementById("ic3").innerHTML = d4
        document.getElementById("ic4").innerHTML = data.distancia4?.time;
        if (data.distancia4?.humidty === undefined) {
          document.getElementById("ic1").innerHTML = '-';
        } else {
          document.getElementById("ic1").innerHTML = data.distancia4?.humidty;
        }
        if (data.distancia4?.pir === undefined) {
          document.getElementById("ic5").innerHTML = '-';
        } else {
          document.getElementById("ic5").innerHTML = data.distancia4?.pir;
        }
        if (data.distancia4?.lux === undefined) {
          document.getElementById("ic6").innerHTML = '-';
        } else {
          document.getElementById("ic6").innerHTML = data.distancia4?.lux;
        }
        if (data.distancia4?.cutoff === undefined) {
          document.getElementById("ic7").innerHTML = '-';
        } else {
          document.getElementById("ic7").innerHTML = data.distancia4?.cutoff;
        }
        if (isNaN(d4) && vista3.current) {
          vista3.current.style.display = 'none';
        } else if (vista3.current) {
          vista3.current.style.display = '';
        }
      }
    });
  }, []);
  return (
    <div>
      <table ref={vista3} border="1">
        <thead>
          <tr>
            <th scope="col">Ubicación</th>
            <th scope="col">Temp (grados)</th>
            <th scope="col">Humedad (%)</th>
            <th scope="col">Pir (Bool)</th>
            <th scope="col">Luz (Vat)</th>
            <th scope="col">cutoff (Vat)</th>
            <th scope="col">Fecha</th>
            <th scope="col">Distancia (m)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Urología: </td>
            <td id="ic2" data-sortable="true"></td>
            <td id="ic1" data-sortable="true"></td>
            <td id="ic5" data-sortable="true"></td>
            <td id="ic6" data-sortable="true"></td>
            <td id="ic7" data-sortable="true"></td>
            <td id="ic4" data-sortable="true"></td>
            <td id="ic3" data-sortable="true"></td>
          </tr>
        </tbody>
      </table>
    </div>

  );
}
export default Mapa5;