import React, { useRef, useEffect } from 'react';
import './App.css';
import io from "socket.io-client";

function Mapa3(username) {
  let vista2 = useRef(null);
  useEffect(() => {
    const socket_url = process.env.REACT_APP_CONECT_SOCKET_PRO;
    const socket = io(socket_url, {
      transports: ["websocket"]
    });
    socket.on('connect', () => {
      socket.emit('enlace_azul', username.username);
    });
    socket.on('datos', (data) => {
      const tc1 = document.getElementById("tc1");
      const tc2 = document.getElementById("tc2");
      const tc3 = document.getElementById("tc3");
      const tc4 = document.getElementById("tc4");
      const tc5 = document.getElementById("tc5");
      const tc6 = document.getElementById("tc6");
      const tc7 = document.getElementById("tc7");

      if (tc2 && tc3 && tc4 && tc5 && tc6 && tc7) {
        document.getElementById("tc2").innerHTML = data.distancia2?.temp;
        let d2 = Math.round(Math.pow(10, ((-59 - (data.distancia2?.rssi)) / (10 * 2.7))) * 100) / 100;;
        document.getElementById("tc3").innerHTML = d2
        document.getElementById("tc4").innerHTML = data.distancia2?.time;
        if (data.distancia2?.humidty === undefined) {
          document.getElementById("tc1").innerHTML = '-';
        } else {
          document.getElementById("tc1").innerHTML = data.distancia2?.humidty;
        }
        if (data.distancia2?.pir === undefined) {
          document.getElementById("tc5").innerHTML = '-';
        } else {
          document.getElementById("tc5").innerHTML = data.distancia2?.pir;
        }
        if (data.distancia2?.lux === undefined) {
          document.getElementById("tc6").innerHTML = '-';
        } else {
          document.getElementById("tc6").innerHTML = data.distancia2?.lux;
        }
        if (data.distancia2?.cutoff === undefined) {
          document.getElementById("tc7").innerHTML = '-';
        } else {
          document.getElementById("tc7").innerHTML = data.distancia2?.cutoff;
        }
        if (isNaN(d2) && vista2.current) {
          vista2.current.style.display = 'none';
        } else if (vista2.current) {
          vista2.current.style.display = '';
        }
      }
    });
  }, []);
  return (
    <div>
      <table ref={vista2} border="1" >
        <thead>
          <tr>
            <th scope="col">Ubicación</th>
            <th scope="col">Temp (grados)</th>
            <th scope="col">Humedad (%)</th>
            <th scope="col">Pir (Bool)</th>
            <th scope="col">Luz (Vat)</th>
            <th scope="col">cutoff (Vat)</th>
            <th scope="col">Fecha</th>
            <th scope="col">Distancia (m)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Acabados: </td>
            <td id="tc2" data-sortable="true"></td>
            <td id="tc1" data-sortable="true"></td>
            <td id="tc5" data-sortable="true"></td>
            <td id="tc6" data-sortable="true"></td>
            <td id="tc7" data-sortable="true"></td>
            <td id="tc4" data-sortable="true"></td>
            <td id="tc3" data-sortable="true"> </td>
          </tr>
        </tbody>
      </table>
    </div>

  );
}
export default Mapa3;